<template>
  <nut-form :model-value="formData" ref="ruleForm">
    
    <nut-form-item label="姓名" prop="real_name" required :rules="[{ required: true, message: '请填写姓名' }]">
      <input class="nut-input-text" @blur="customBlurValidate('real_name')" v-model="formData.real_name"
             placeholder="请输入姓名" type="text" />
    </nut-form-item>

    <nut-form-item label="联系电话" prop="mobile" required :rules="[{ required: true, message: '请填写联系电话' },{ validator: mobileValidator, message: '电话格式不正确' }]">
      <input class="nut-input-text" v-model="formData.mobile" placeholder="请输入联系电话" type="text" />
    </nut-form-item>
    
    <nut-form-item label="送货地址" prop="address_str" required :rules="[{ required: true, message: '请选择地址' }]">
      <input class="nut-input-text"   v-model="formData.address_str"  @click="state.visible = true" readonly placeholder="请选择地址" type="text"/>
      <nut-cascader title="地址选择" v-model:visible="state.visible" v-model="state.value" @change="events.change" @path-change="events.pathChange" lazy :lazy-load="state.lazyLoad"></nut-cascader>
    </nut-form-item>

    <nut-form-item label="详细地址" prop="address_detail" required :rules="[{ required: true, message: '请填写地址' }]">
      <input class="nut-input-text" v-model="formData.address_detail" placeholder="请输入详细地址" type="text" />
    </nut-form-item>
    
    <nut-form-item label="设为默认收货地址">
      <nut-switch v-model="formData.is_default"></nut-switch>
    </nut-form-item>
    
    <nut-cell>
      <nut-button type="primary" size="small" style="margin-right: 10px" @click="submit">提交</nut-button>
    </nut-cell>
  </nut-form>
</template>
<script>
import { reactive, ref } from 'vue';
import * as addressApi from "../../api/address";
import { Toast } from "@nutui/nutui";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const param = useRoute().query.param;
    const mfcId = useRoute().query.mfc_id;
    const state = reactive({
      visible: false,
      value: [],
      lazyLoad(node, resolve) {
        // root表示第一层数据
        if(node.id == 0){
          resolve([]);
        }
        if (node.root) {
          getCityList(1,resolve);
        } else {
          getCityList(node.id,resolve);
        }
      }
    });
    
    const getCityList = async (cityId, resolve) => {
      let res = await addressApi.getCityList({ "city_pid": cityId});
      if(res.code == 0){
        var list = [];
        var cityList = res.data.list;
        var cityListLength = cityList.length;
        var cityLevel;
        for( var i=0; i < cityListLength; i++) {
          cityLevel = cityList[i]["city_level"] - 1;
          list.push({
            id: cityList[i].city_id,
            name: cityList[i]["city_name"],
            city_name: cityList[i]["city_name"],
            city_level: cityLevel,
            value: cityList[i]["city_name"],
            text: cityList[i]["city_name"],
          });
        }
        if(list.length >0){
          list.push({
            id: 0,
            name: "暂不选择",
            city_name: "暂不选择",
            value: "暂不选择",
            text: "暂不选择",
          });
        }
        resolve(list);
      } else {
        Toast.text(res.message);
      }
    };

    const events = {
      change(...args) {
        console.log('change', ...args);
      },
      pathChange(...args) {
        var addressStr = "";
        args[0].forEach((item) => {
          console.log("foreach",item);
          if(item != null && item.id != 0){
            addressStr += item.name;
            formData.city_id = item.id;
          }
        });
        formData.address_str = addressStr;
      },
    };

    //form表单数据
    const formData = reactive({
      real_name: '',
      mobile: '',
      city_id: '',
      address_detail: '',
      address_str: '',
      is_default: 0,
    });
    const ruleForm = ref();
    const addAddress = async (data) => {
      const res = await addressApi.add(data);
      if(res.code == 0){
        if(param != undefined){
          router.push("/order/create?param=" + param +"&mfc_id="+mfcId  +"&address_id="+res.data.address_id);
        }else{
          router.push("/address/list");
        }
 
      }else{
        Toast.text(res.message);
      }
    };
    const submit = () => {
      ruleForm.value.validate().then(({ valid, errors }) => {
        if (valid) {
          addAddress({
            real_name: formData.real_name,
            mobile: formData.mobile,
            city_id: formData.city_id,
            address_detail: formData.address_detail,
            is_default: formData.is_default == true ? 1 : 0,
          });
        } else {
          console.log('error submit!!', errors,formData);

        }
      });
    };
    // 失去焦点校验
    const customBlurValidate = (prop) => {
      ruleForm.value.validate(prop).then(({ valid, errors }) => {
        if (valid) {
          console.log('success', formData);
        } else {
          console.log('error submit!!', errors);
        }
      });
    };

    // 函数校验
    const customValidator = (val) => /^\d+$/.test(val);
    // Promise 异步校验
    const mobileValidator = (val) => {
      return new Promise((resolve) => {
        resolve(/^400(-?)[0-9]{7}$|^1\d{10}$|^0[0-9]{2,3}-[0-9]{7,8}$/.test(val));
      });
    };

    return { state, events, formData, customBlurValidate ,ruleForm ,customValidator, mobileValidator, submit };
  }
}
</script>
